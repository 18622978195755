import { useContext } from "react";
import { Context } from "./../context/Context";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const useApiAxios = () => {
  const ENDPOINT = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const SET_PASSWORD = "dj-rest-auth/registration/";
  const LOGIN = "dj-rest-auth/login/";
  const USER = "dj-rest-auth/user/";
  const PASSWORD_RESET = "dj-rest-auth/password/reset/";
  const RESET_PASSWORD_CONFIRM = "dj-rest-auth/password/reset/confirm/";

  const ONBOARDING = "onboarding/";
  const COMPLETE_ONBOARDING = "complete_onboarding/";
  const CARD_SETUP = "payment_setup/";
  const UPFRONT_PAYMENT = "upfront/";
  const GRATUITY_PAYMENT = "gratuity/";
  const MEMBERSHIP_PAYMENT = "membership_subscription/";

  const ERROR_MESSAGE = "Something went wrong, please try again.";
  const {
    setUserData,
    setIsAuthorized,
    setIsLoading,
    openErrorSnackbar,
    openInfoSnackbar,
  } = useContext(Context);

  // HELPER FUNCTIONS //
  const getSessionStorageData = () => {
    const data = sessionStorage.getItem("data");
    if (data) {
      return JSON.parse(data);
    } else {
      return null;
    }
  };
  const removeSingleDataSessionStorageData = (name) => {
    const data = sessionStorage.getItem("data");
    if (data) {
      const objData = JSON.parse(data);
      delete objData[name];
      sessionStorage.setItem("data", JSON.stringify(objData));
      return null;
    } else {
      return null;
    }
  };
  const setSessionStorageData = (name, value) => {
    const data = sessionStorage.getItem("data");
    const objData = JSON.parse(data);
    sessionStorage.setItem(
      "data",
      JSON.stringify({ ...objData, [name]: value })
    );
  };
  const sessionStorageCheck = () => {
    try {
      sessionStorage.setItem("testKey", "test value");
      sessionStorage.removeItem("testKey");
      return true;
    } catch (error) {
      return false;
    }
  };
  ////////////////////////////////////////////////////

  // USER CALLS //
  const LogoutApi = async () => {
    setIsAuthorized(false);
    setUserData({});
    sessionStorage.removeItem("access");
    sessionStorage.removeItem("refresh");
    navigate("/login");
  };
  const loginApi = async ({ password, username, loggedIn }) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${ENDPOINT}${LOGIN}`, {
        password,
        username,
      });
      setIsAuthorized(true);
      setUserData({ ...response?.data?.user, ...loggedIn });
      sessionStorage.setItem("access", response.data.access);
      sessionStorage.setItem("refresh", response.data.refresh);
      navigate("/dashboard");
      setIsLoading(false);

      // axiosInstance = axios.create({
      //   baseURL: ENDPOINT,
      //   headers: {
      //     Authorization: `Bearer ${response.data.access}`,
      //   },
      // });
      return { error: false, data: response.data };
    } catch (error) {
      setIsLoading(false);

      if (
        error?.response.data?.non_field_errors[0] ===
        "Unable to log in with provided credentials."
      ) {
        openErrorSnackbar("Invalid credentials");
        return { error: true, message: "Invalid credentials" };
      } else {
        openErrorSnackbar(ERROR_MESSAGE);

        return {
          error: true,
          message: ERROR_MESSAGE,
        };
      }
    }
  };
  const getUserInfoApi = async () => {
    if (sessionStorage.getItem("access")) {
      try {
        setIsLoading(true);
        const response = await axios.get(`${ENDPOINT}${USER}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("access")}`,
          },
        });
        setUserData(response.data);
        setIsAuthorized(true);
        setIsLoading(false);

        return { error: false, data: response.data };
      } catch (error) {
        setIsLoading(false);
        sessionStorage.removeItem("access");
        sessionStorage.removeItem("refresh");
        // navigate("/");
        setIsAuthorized(false);
        console.log(error.response.statusCode === 401, error.response.data);
        return { error: true, data: error.response.data };
      }
    } else {
      // navigate("/");
      setIsAuthorized(false);
    }
  };
  const setPasswordApi = async (data) => {
    // Used to modify emails with a "+" in them
    const modifiedEmail = data.email?.split(" ").join("+");

    try {
      setIsLoading(true);
      const response = await axios.post(
        `${ENDPOINT}${SET_PASSWORD}`,
        {
          username: modifiedEmail,
          password1: data.password1,
          password2: data.password2,
          email: modifiedEmail,
          stripe_id: data.stripeId,
        },
        {}
      );
      setIsLoading(false);
      openInfoSnackbar("Account created successfully");
      navigate("/login");

      return { error: false, data: response?.data };
    } catch (error) {
      let message;
      // Display different message depending if email already registered. Change if API error message changes.
      if (
        error.response?.data?.email &&
        error.response?.data?.email[0]?.indexOf("already registered") > -1
      ) {
        message = "Email is already registered";
      } else {
        message = ERROR_MESSAGE;
      }
      openErrorSnackbar(message);
      setIsLoading(false);
    }
  };

  const resetPasswordApi = async (email) => {
    try {
      setIsLoading(true);
      console.log(email);
      const response = await axios.post(`${ENDPOINT}${PASSWORD_RESET}`, {
        email: email.username,
      });
      setIsLoading(false);
      openInfoSnackbar("Check your email");
      navigate("/");
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      openErrorSnackbar(ERROR_MESSAGE);
      navigate("/");
      throw new Error(`Error with response ${error.toString()}`);
    }
  };
  const setRestPasswordApi = async (data) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${ENDPOINT}${RESET_PASSWORD_CONFIRM}`,
        data,
        {}
      );
      setIsLoading(false);
      openInfoSnackbar("Password reset successfully");
      navigate("/login");

      return { error: false, data: response.data };
    } catch (error) {
      openErrorSnackbar(ERROR_MESSAGE);
      setIsLoading(false);
    }
  };

  ////////////////////////////////////////////////////

  // ONBOARDING & PAYMENT CALLS //
  const completeOnboardingApi = async (data) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${ENDPOINT}${COMPLETE_ONBOARDING}`, {
        stripe_id: data,
        email: getSessionStorageData()?.email,
      });

      removeSingleDataSessionStorageData("email");

      setIsLoading(false);
      openInfoSnackbar("Email sent successfully");
      return { error: false, data: response.data };
    } catch (error) {
      setIsLoading(false);
    }
  };

  const startOnboarding = async (data, totalPrice, priceBeforeDiscount) => {
    const one_time_purchase = totalPrice > 0 ? true : false;
    try {
      setIsLoading(true);
      const response = await axios.post(`${ENDPOINT}${ONBOARDING}`, {
        ...data,
      });
      console.log("Start onboarding,", response?.status);

      setSessionStorageData("email", data.email);
      setSessionStorageData("price", totalPrice);
      setSessionStorageData("price_before_discount", priceBeforeDiscount);
      setSessionStorageData("salesforce_opp_id", data.salesforce_opp_id);
      setSessionStorageData("one_time_purchase", one_time_purchase);

      setIsLoading(false);
      return { error: false, data: response.data };
    } catch (error) {
      openErrorSnackbar(ERROR_MESSAGE);
      setIsLoading(false);
    }
  };

  const cardSetup = async (stripe_id) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${ENDPOINT}${CARD_SETUP}`, {
        stripe_id,
      });
      setIsLoading(false);
      return { data: response.data };
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      openErrorSnackbar(ERROR_MESSAGE);
      navigate("/");
      throw new Error(`Error with response ${error.toString()}`);
    }
  };

  const getUpfrontSecret = async (stripe_id, amount, salesforce_opp_id) => {
    // Need to multiply price by 100 to convert into proper dollar amount
    const price = parseInt(amount * 100);
    try {
      setIsLoading(true);
      const response = await axios.post(`${ENDPOINT}${UPFRONT_PAYMENT}`, {
        stripe_id,
        price,
        salesforce_opp_id,
      });
      setIsLoading(false);
      return { data: response.data };
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      openErrorSnackbar("One-time payment is unavailable at the moment.");
      navigate("/");
      throw new Error(`Error with response ${error.toString()}`);
    }
  };
  const getMembershipSecret = async (stripe_id, salesforce_opp_id, annual) => {
    try {
      setIsLoading(true);

      const response = await axios.post(`${ENDPOINT}${MEMBERSHIP_PAYMENT}`, {
        stripe_id,
        salesforce_opp_id,
        annual,
      });

      setIsLoading(false);
      return { data: response.data };
    } catch (error) {
      console.log(error);
      setIsLoading(false);

      openErrorSnackbar("Membership sign up is unavailable at the moment.");
      navigate("/");
      throw new Error(`Error with response ${error.toString()}`);
    }
  };
  const getGratuitySecret = async (stripe_id, amount, salesforce_opp_id) => {
    // Need to multiply price by 100 to convert into proper dollar amount
    const price = parseInt(amount * 100);
    try {
      setIsLoading(true);

      const response = await axios.post(`${ENDPOINT}${GRATUITY_PAYMENT}`, {
        stripe_id,
        salesforce_opp_id,
        price,
      });

      setIsLoading(false);
      return { data: response.data };
    } catch (error) {
      console.log(error);
      setIsLoading(false);

      openErrorSnackbar("Tipping is unavailable at the moment.");
      navigate("/");
      throw new Error(`Error with response ${error.toString()}`);
    }
  };

  ////////////////////////////////////////////////////

  return {
    completeOnboardingApi,
    setPasswordApi,
    loginApi,
    getUserInfoApi,
    LogoutApi,
    setSessionStorageData,
    getSessionStorageData,
    sessionStorageCheck,
    cardSetup,
    getUpfrontSecret,
    getMembershipSecret,
    getGratuitySecret,
    startOnboarding,
    resetPasswordApi,
    setRestPasswordApi,
  };
};

export default useApiAxios;
